import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useCookies } from 'react-cookie';
import SEO from "../components/seo/seo"
import Layout from "../components/layout/layout"
import AppBar from "../components/appBar/appBar"
import MainSection from "../components/mainSection/mainSection"
import SecondarySection from "../components/secondarySection/secondarySection"
import PageSection from "../components/pageSection/pageSection"
import Card from "../components/card/card"
import LaunchForm from "../components/launchForm/launchForm"
import OptOut from "../components/optOut/optOut"
import IFrame from "../components/iFrame/iFrame"
import Modal from "../components/modal/modal"

import * as ACTIONS from "../state/actions"

const tcPdf = <IFrame file="../TC.pdf" title="Términos de uso"/>;
const pcPdf = <IFrame file="../PC.pdf" title="Política de privacidad"/>;
// const footerDesc = "";

const Herramientas = () => {
  const appState = useSelector(state => state.appReducer);
  const dispatch = useDispatch();

  const [cookies, setCookie] = useCookies(['consent']);

  const launchFormHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });

    const modal = {
      display: true,
      content: <LaunchForm lang={appState.lang} title="Registra los servicios que estas interesado y recibe una oferta especial 🎁" extraFields="services" amplitude={appState.amplitude} amplitudeClickEvent="ToolsSignUpCreated"/>
    };

    dispatch(ACTIONS.showModal(modal))
  };

  const termsUseHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: tcPdf }))
  };

  const privacyPolicyHandler = () => {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    dispatch(ACTIONS.showModal({display: true, content: pcPdf }))
  };

  const cookieHandler = () => {
    setCookie("consent", "dismiss", { path: '/' });
  };

  const closeModal = () => dispatch(ACTIONS.hideModal());

  return (
    <Layout lang={appState.lang} privacyPolicyAction={privacyPolicyHandler} termsUseHandlerAction={termsUseHandler}>
      <SEO lang={appState.lang} page="herramientas" title="Crece tu negocio con herramientas EVVA" description="Acelera tu negocio con nuestros aliados y herramientas para Startups, PyMEs y Freelancers."/>
      {appState.modal.display && <Modal content={appState.modal.content} closeModal={closeModal}/> }
      {!cookies.consent &&
      <OptOut text="Utilizamos cookies para garantizar que tengas la mejor experiencia en nuestro sitio web." privacyPolicyAction={privacyPolicyHandler} privacyPolicyLabel="Ver Política de privacidad" cookieAction={cookieHandler}/>
      }
      <MainSection title="Acelera tu negocio con nuestro Toolbox" position="left" image="hero-toolbox.jpg" actionLabel="Regístrate, es gratis" action={launchFormHandler} amplitude={appState.amplitude} amplitudeClickEvent="ClickPreSignUp">
        <AppBar pathname={appState.path}/>
      </MainSection>
      <PageSection maxColumns="2" title="Aliados y herramientas para Startups, PyMEs y Freelancers">
        <Card
            subtitle="Bind Cloud ERP"
            description="<p>El sistema de administración en la nube más fácil y completo para hacer crecer tu negocio.</p>"
            descriptionColor="#363636"
            image="bind-icon.png"
            actionLabel="Estoy interesado"
            action={launchFormHandler}
            link="https://www.bind.com.mx/"
            linkLabel="Conoce más"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Mifiel Firma Digital"
            description="<p>Agiliza tu negocio de manera segura y fácil. Firma digitalmente cualquier documento legal en México.</p>"
            descriptionColor="#363636"
            image="mifiel-icon.png"
            actionLabel="Estoy interesado"
            action={launchFormHandler}
            link="https://www.mifiel.com/es/"
            linkLabel="Conoce más"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Legal "
            description="<p>Resuelve cualquier duda legal durante la creación y crecimiento de tu empresa.</p>"
            descriptionColor="#363636"
            image="legal-icon.png"
            actionLabel="Estoy interesado"
            action={launchFormHandler}
            link="/herramientas"
            linkLabel="Próximamente"
            horizontal
            padding
            shadow
        />
        <Card
            subtitle="Factura Digital"
            description="<p>Emite y recupera tus facturas desde EVVA, todo se queda bajo control.</p>"
            descriptionColor="#363636"
            image="invoice-icon.png"
            actionLabel="Estoy interesado"
            action={launchFormHandler}
            link="/herramientas"
            linkLabel="Próximamente"
            horizontal
            padding
            shadow
        />
      </PageSection>
      <SecondarySection
        title="¿Quieres ser parte de la Comunidad Empresarial EVVA?"
        shortDescription="Déjanos ayudarte a crecer juntos."
        description="<p>Contáctanos directamente en: <a href='mailto:info@evvafinanzas.com'>info@evvafinanzas.com<a/></p>"
        position="center"
        image="evva-contact.jpg"
        actionLabel="Regístrate, es gratis"
        action={launchFormHandler}
        amplitude={appState.amplitude}
        amplitudeClickEvent="ClickPreSignUp"
      />
    </Layout>
  )
};

export default Herramientas